<template>
  <v-container fluid class="pa-0">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="pt-2 pl-8">
      <span class="font-weight-medium grey--text text--darken-4 text-h6">{{
        $t("excavationDetails")
      }}</span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card
        width="100%"
        class="rounded-lg"
        elevation="0"
        style="border: 1px solid #e0e0e0; border-radius: 8%"
      >
        <MapView />
        <v-card-actions>
          <v-row justify="center">
            <span
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2"
              >{{ $store.state.Ticket.ticketData ? streetAddress : "" }}</span
            >
            <a
              v-if="$store.state.Ticket.isEdit !== 'UpdateTicket'"
              class="primary--text my-2 body-2"
              @click="$emit('editLocation')"
            >
              <img
                src="@/assets/images/edit_location.svg"
                role="img"
                alt="edit location Logo"
              />
              <span class="mb-auto">{{ $t("editLocation") }}</span></a
            >
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.county ? '' : 'my-auto'"
            >
              {{ $t("county") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.county &&
                $store.state.Ticket.ticketData.county != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.county
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="county"
                outlined
                dense
                required
                hide-details
                placeholder="Enter County"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.place ? '' : 'my-auto'"
            >
              {{ $t("place") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.place &&
                $store.state.Ticket.ticketData.place != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.place
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="place"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Place"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.street ? '' : 'my-auto'"
            >
              {{ $t("street") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.street &&
                $store.state.Ticket.ticketData.street != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{ $store.state.Ticket.ticketData ? streetAddress : "" }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="street"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Street"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross1 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross1") }} :
            </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross1"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 1"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross2 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross2") }} :
            </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross2"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 2"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1"> {{ $t("location") }} : </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                streetLocation.description ? streetLocation.description : "—"
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-row
    >
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
    >
      <v-col>
        <v-row no-gutters class="mt-2">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
            >{{ $t("811Center") }}</span
          >
        </v-row>
        <v-row
          no-gutters
          :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
        >
          <v-col>
            <img
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/utah-811.svg"
              role="img"
              alt="UTAH 811 Center Logo"
            />
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mt-10' : 'mt-10 ml-10'">
            <v-row no-gutters>
              <span
                class="font-weight-normal grey--text text--darken-1 text-caption"
                >{{ $t("excavatorType") }}</span
              >
            </v-row>
            <v-row no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ companyExcavatorType }}</span
              >
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
          <v-divider></v-divider>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-4 mb-10">
            <AssociateTicketExcvDetails
              v-if="checkPermission('associateTicketExcavationDetails')"
              ref="associateTicket"
            />
            <v-row class="mb-2" no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                >{{ $t("tellAboutExcavation") }}</span
              >
            </v-row>
            <v-row class="mb-2" no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                >Meet ?</span
              >
            </v-row>
            <v-row no-gutters class="mt-4 mb-6">
              <span
                class="mr-4 my-auto mt-1 font-weight-regular grey--text text--darken-2 text-subtitle-2"
              >
                {{ $t("NO") }}
              </span>
              <v-switch
                v-model="meet"
                inset
                hide-details
                color="info"
                class="mt-0 info--text switch-track-inset-opacity switch-track-inset-height"
                background-color="white"
              >
                <template v-slot:label>
                  <div
                    class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                  >
                    {{ $t("YES") }}
                  </div>
                </template>
              </v-switch>
            </v-row>
            <v-row no-gutters justify="center" v-if="meet">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="work_date"
                    label="Excavation Start Date"
                    persistent-hint
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    :error-messages="dateFormattedErrors"
                    v-on="on"
                    @input="$v.work_date.$touch()"
                    @blur="$v.work_date.$touch()"
                    :disabled="$store.state.Ticket.isEdit === 'UpdateTicket'"
                    ><template v-slot:label>
                      <span class="red--text"> *</span>
                      {{ $t("excavationStartDate") }}
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  :min="getNowDate"
                  :max="getEndDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduleTime"
                    :label="$t('time')"
                    format="ampm"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    persistent-hint
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-3' : ''"
                    :error-messages="scheduleTimeError"
                    @input="$v.scheduleTime.$touch()"
                    @blur="$v.scheduleTime.$touch()"
                    readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  :menu-props="{ bottom: true, offsetY: true }"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(time)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact"
                label="Site Contact Name"
                outlined
                dense
                required
                :error-messages="contactErrors"
                @input="$v.contact.$touch()"
                @blur="$v.contact.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("siteContactName") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact_phone"
                label="Site Contact Phone"
                outlined
                dense
                required
                :error-messages="contactPhoneErrors"
                @input="$v.contact_phone.$touch()"
                @blur="$v.contact_phone.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("siteContactPhone") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="done_for"
                label="Work Done For"
                outlined
                dense
                required
                :error-messages="workDoneForErrors"
                @input="$v.done_for.$touch()"
                @blur="$v.done_for.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("workDoneFor") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="subdivision"
                label="Sub-Division/Complex/Building Name"
                outlined
                dense
                :error-messages="subdivisionErrors"
                @input="$v.subdivision.$touch()"
                @blur="$v.subdivision.$touch()"
              ></v-text-field>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="lot"
                label="Lot/Unit/Building Number"
                outlined
                dense
                :error-messages="lotErrors"
                @input="$v.lot.$touch()"
                @blur="$v.lot.$touch()"
              ></v-text-field>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
              >
                {{ $t("excavationAreaOfLot") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span v-if="excavationAreaTextBox">{{
                  $t("excavationTextAreaOfLotToolTip")
                }}</span>
                <span v-else>{{ $t("excavationAreaOfLotToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <div v-if="excavationAreaTextBox">
              <v-row no-gutters>
                <v-text-field
                  v-model="extent"
                  label="Enter Excavation Area of Lot"
                  outlined
                  :hide-details="excavationAreaErrors.length == 0"
                  dense
                  required
                  :error-messages="excavationAreaErrors"
                  @input="$v.extent.$touch()"
                  @blur="$v.extent.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> Enter
                    {{ $t("excavationAreaOfLot") }}
                  </template></v-text-field
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="disableExcavationAreaTextBox()"
                  >{{ $t("searchFromList") }}</a
                >
              </v-row>
            </div>
            <div v-else-if="!excavationAreaTextBox">
              <v-row no-gutters>
                <v-select
                  v-model="extent"
                  :items="utahSelectExcavationAreaOnProperty"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Excavation Area of Lot"
                  outlined
                  dense
                  required
                  item-value="text"
                  :hide-details="excavationAreaErrors.length == 0"
                  :error-messages="excavationAreaErrors"
                  @change="emptyExcavationArea"
                  @input="$v.extent.$touch()"
                  @blur="$v.extent.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span>
                    {{ $t("excavationAreaOfLot") }}
                  </template></v-select
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="enableExcavationAreaTextBox()"
                  >{{ $t("dontSeeExcavationAreaOfLot") }}</a
                >
              </v-row>
            </div>
            <v-row no-gutters class="mt-6 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
              >
                <span class="red--text mr-1"> *</span
                >{{ $t("excavationAreaInRoad") }}</span
              >
            </v-row>
            <div>
              <v-radio-group v-model="excavation_area_in_road" column>
                <v-row no-gutters>
                  <v-col>
                    <v-radio value="Short">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("short") }}
                          <span
                            class="font-weight-regular grey--text text--darken-1 text-caption"
                          >
                            {{ $t("shortSubLabel") }}</span
                          >
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="Long">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("long") }}
                          <span
                            class="font-weight-regular grey--text text--darken-1 text-caption"
                          >
                            {{ $t("longSubLabel") }}</span
                          >
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="Other">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("other") }}
                          <span
                            class="font-weight-regular grey--text text--darken-1 text-caption"
                          >
                            {{ $t("otherSubLabel") }}</span
                          >
                          <v-tooltip
                            :right="!$vuetify.breakpoint.smAndDown"
                            :bottom="$vuetify.breakpoint.smAndDown"
                            max-width="400"
                            class="pa-0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-4"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t("excavationAreaInRoadToolTip") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="None">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("none") }}
                          <span
                            class="font-weight-regular grey--text text--darken-1 text-caption"
                          >
                            {{ $t("noneSubLabel") }}</span
                          >
                        </div>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
              >
                <span class="red--text mr-1"> *</span
                >{{ $t("locationOnProperty")
                }}<v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="400"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("locationOnPropertyTooltip") }}</span>
                </v-tooltip></span
              >
            </v-row>
            <div>
              <v-radio-group v-model="direction_of_road" column>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-radio value="North">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("north") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="South">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("south") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="East">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("east") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="West">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("west") }}
                        </div>
                      </template>
                    </v-radio>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'pa-0'"
                  >
                    <v-radio value="Southeast">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("southeast") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="Southwest">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("southwest") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="Northeast">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("northeast") }}
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="Northwest">
                      <template v-slot:label>
                        <div
                          class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                        >
                          {{ $t("northwest") }}
                        </div>
                      </template>
                    </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
              >
                {{ $t("typeOfWork") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("typeOfWorkToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <div v-if="typeOfWorkTextBox">
              <v-row no-gutters>
                <v-text-field
                  v-model="work_type"
                  label="Enter Type of Work"
                  outlined
                  dense
                  required
                  :hide-details="typeOFWorkErrors.length == 0"
                  :error-messages="typeOFWorkErrors"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> Enter
                    {{ $t("typeOfWork") }}
                  </template></v-text-field
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="disableTypeOfWorkTextBox()"
                  >{{ $t("searchFromList") }}</a
                >
              </v-row>
            </div>
            <div v-else>
              <v-row no-gutters>
                <v-select
                  v-model="work_type"
                  :items="workTypeOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Type of Work"
                  outlined
                  dense
                  required
                  item-value="text"
                  :error-messages="typeOFWorkErrors"
                  :hide-details="typeOFWorkErrors.length == 0"
                  @change="emptyWorkType"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> {{ $t("typeOfWork") }}
                  </template></v-select
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="enableTypeOfWorkTextBox()"
                  >{{ $t("dontSeeTypeOfWork") }}</a
                >
              </v-row>
            </div>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("gateCode") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("gateCodeToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="gate_code"
                label="Gate Code"
                outlined
                dense
                required
                :error-messages="gateCodeErrors"
                @input="$v.gate_code.$touch()"
                @blur="$v.gate_code.$touch()"
              ></v-text-field>
            </v-row>
            <v-row no-gutters class="mt-2 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalComments") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("utahAdditionalCommentsToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-textarea
                v-model="remarks"
                label="Comments"
                :error-messages="commentsErrors"
                rows="2"
                counter="120"
                outlined
                dense
                required
                @input="$v.remarks.$touch()"
                @blur="$v.remarks.$touch()"
              ></v-textarea>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalInformation") }}
                <span
                  class="font-weight-normal grey--text text--darken-1 text-caption"
                >
                  {{ $t("checkAllApply") }}</span
                ></span
              >
            </v-row>
            <div>
              <v-row no-gutters>
                <v-checkbox v-model="boring" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("utahBoringLabel") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox v-model="white_paint" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("utahWhiteFlagLabel") }}
                    </div>
                  </template></v-checkbox
                >
              </v-row>
              <v-row no-gutters class="mb-16">
                <v-checkbox v-model="blasting" hide-details>
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("utahBlastingLabel") }}
                      <span
                        class="font-weight-regular grey--text text--darken-1 text-subtitle-2"
                      >
                        {{ $t("utahBlastingSubLabel") }}
                      </span>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row
                no-gutters
                class="mt-4 mb-2"
                v-if="$store.state.Ticket.isEdit === 'UpdateTicket'"
              >
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                  >{{ $t("membersToAdd") }}
                </span>
              </v-row>
              <v-row
                no-gutters
                class="mb-16"
                v-if="$store.state.Ticket.isEdit === 'UpdateTicket'"
              >
                <v-autocomplete
                  v-model="selectedMembersToAdd"
                  :items="membersToAddList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="$t('membersToAdd')"
                  outlined
                  dense
                  required
                  multiple
                  item-text="mbcode_name"
                  item-value="mbcode"
                  @input="searchCountyMembers = null"
                  :search-input.sync="searchCountyMembers"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-text-field
                        v-model="searchCountyMembers"
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('searchMembersToAdd')"
                        single-line
                        hide-details
                        flat
                        dense
                        variant="underlined"
                        clear-icon="mdi-close-circle"
                        clearable
                      >
                      </v-text-field>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      size="small"
                      density="compact"
                      v-bind="attrs"
                      :input-value="selected"
                    >
                      <span class="pr-2">
                        {{ item.mbcode_name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        mdi-close-circle
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-row>
            </div>
            <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="mb-16">
              <v-btn
                color="primary"
                class="text-none rounded-lg elevation-0"
                outlined
                width="90"
                @click="backButton()"
                :disabled="$store.state.Ticket.isEdit === 'UpdateTicket'"
              >
                {{ $t("back") }}
              </v-btn>

              <v-btn
                class="ml-6 primary text-none rounded-lg elevation-0"
                width="90"
                depressed
                :disabled="$v.$invalid"
                @click="continueButton"
              >
                {{ $t("continue") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-card
          width="90%"
          class="rounded-lg mx-4"
          elevation="0"
          style="border: 1px solid #e0e0e0; border-radius: 8%"
        >
          <v-card-title class="pt-2">
            <v-row>
              <v-col class="pt-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-2 my-2"
                  >{{
                    $store.state.Ticket.ticketData ? streetAddress : ""
                  }}</span
                >
              </v-col>
              <v-col class="d-flex justify-end pt-2">
                <a
                  v-if="$store.state.Ticket.isEdit !== 'UpdateTicket'"
                  class="primary--text my-2 body-2"
                  @click="$emit('editLocation')"
                >
                  <img
                    src="@/assets/images/edit_location.svg"
                    role="img"
                    alt="edit location Logo"
                  />
                  <span class="mb-auto">{{ $t("editLocation") }}</span></a
                ></v-col
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("county") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.county
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="county"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter County"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("place") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.place
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="place"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Place"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("street") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{ $store.state.Ticket.ticketData ? streetAddress : "—" }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="street"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Street"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross1 ? '' : 'my-auto'"
              >
                {{ $t("cross1") }} :
              </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross1"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Cross 1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross2 ? '' : 'my-auto'"
              >
                {{ $t("cross2") }} :
              </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross2"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Cross 2"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="py-1"> {{ $t("location") }} : </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  streetLocation.description ? streetLocation.description : "—"
                }}
              </v-col>
            </v-row>
          </v-card-text>
          <MapView :is-edit-view="isEditView" /> </v-card
      ></v-col>

      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        horizontal
        height="70"
        class="px-2 py-4"
        ><v-row no-gutters justify="space-between">
          <v-col cols="1" class="pa-0">
            <v-btn
              outlined
              color="primary"
              class="rounded-lg"
              height="50"
              :disabled="$store.state.Ticket.isEdit === 'UpdateTicket'"
              @click="backButton"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-btn
              color="primary"
              class="text-none rounded-lg font-weight-regular text-h6 white--text"
              height="50"
              block
              :disabled="$v.$invalid"
              @click="continueButton"
              ><span class="white--text">{{ $t("continue") }}</span>
            </v-btn>
          </v-col>
        </v-row></v-bottom-navigation
      >
    </v-row>
  </v-container>
</template>
<script>
import excavationAreaJSON from "@/static/excavationAreaOnProperty.json";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, maxLength } from "vuelidate/lib/validators";
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
const dateText = helpers.regex(
  "date",
  /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
);
export default {
  components: {
    MapView: () => import("../../MapView.vue"),
    AssociateTicketExcvDetails: () =>
      import("../../AssociateTicketExcvDetails.vue"),
  },
  mixins: [validationMixin, UserDataMixin],
  validations() {
    const validationFieldsObject = {
      contact: { required },
      contact_phone: { required, phone },
      work_type: { required },
      lot: { maxLength: maxLength(10) },
      subdivision: { maxLength: maxLength(40) },
      excavation_area_in_road: { required },
      direction_of_road: { required },
      done_for: { required },
      extent: { required },
      gate_code: { maxLength: maxLength(12) },
      remarks: { maxLength: maxLength(120) },
    };
    if (this.meet) {
      validationFieldsObject.work_date = {
        required,
        dateText,
      };
      validationFieldsObject.scheduleTime = { required };
    }
    return validationFieldsObject;
  },
  props: {
    mapView: {
      type: String,
      default: () => "",
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      legalDates:{},
      membersToAddList: [],
      selectedMembersToAdd: "",
      center: this.$store.state.Ticket.ticketData.center,
      picker: new Date(),
      menu1: false,
      typeOfWorkTextBox: false,
      excavationAreaTextBox: false,
      mapViewHtml: "",
      startDate: "",
      utahSelectExcavationAreaOnProperty:
        excavationAreaJSON.utahExcavationAreaOnProperty,
      workTypeOptions: [],
      date: "",
      work_date: "",
      contact: "",
      contact_phone: "",
      work_type: "",
      subdivision: "",
      lot: "",
      done_for: "",
      // Excavation Area of Lot is mapped to extent for UTAH
      extent: "",
      remarks: "",
      gate_code: "",
      white_paint: false,
      blasting: false,
      boring: false,
      excavation_area_in_road: "",
      direction_of_road: "",
      cross1: "",
      cross2: "",
      place: "",
      street: "",
      county: "",
      meet: false,
      NewtinComment: "",
      NewtinRemark: "",
      utahRemark: "",
      utahComments: "",
      time: null,
      scheduleTime: null,
      menu2: false,
      searchCountyMembers: null,
    };
  },
  computed: {
    streetLocation() {
      return this.$store.state.Ticket.ticketData.streetLocation;
    },
    getNowDate() {
      let tempPickerDate = new Date(this.picker);
      var beginDate = new Date(
        tempPickerDate.setDate(tempPickerDate.getDate())
      );
      while (!this.disableWeekends(beginDate)) {
        beginDate.setDate(beginDate.getDate() + 1);
      }
      this.setStartDate(beginDate);
      return beginDate.toISOString().slice(0, 10);
    },
    getEndDate() {
      let tempPickerDate = new Date(this.picker);
      var endDate = new Date(this.legalDates.workDates ? this.legalDates.workDates[this.legalDates.workDates.length - 1] : tempPickerDate.setDate(tempPickerDate.getDate() + 9));
      return moment(
        endDate
      ).format("YYYY-MM-DD");
    },
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return item.abbrev === this.center;
          }
        )[0].excavator_type;
      }
      return excavatorType;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required &&
        errors.push(this.$t("contactNameRequiredError"));
      return errors;
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.work_date.$dirty) return errors;
      !this.$v.work_date.required &&
        errors.push(this.$t("dateFormattedRequiredError"));
      !this.$v.work_date.dateText &&
        errors.push(this.$t("dateFormattedInvalidError"));
      return errors;
    },
    contactPhoneErrors() {
      const errors = [];
      if (!this.$v.contact_phone.$dirty) return errors;
      !this.$v.contact_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      !this.$v.contact_phone.required &&
        errors.push(this.$t("contactPhoneRequiredError"));
      return errors;
    },
    workDoneForErrors() {
      const errors = [];
      if (!this.$v.done_for.$dirty) return errors;
      !this.$v.done_for.required &&
        errors.push(this.$t("workDoneForRequiredError"));
      return errors;
    },
    excavationAreaErrors() {
      const errors = [];
      if (!this.$v.extent.$dirty) return errors;
      !this.$v.extent.required &&
        errors.push(this.$t("excavationAreaOfLotRequiredError"));
      return errors;
    },
    directionoFRoadErrors() {
      const errors = [];
      if (!this.$v.directionoFRoad.$dirty) return errors;
      !this.$v.directionoFRoad.required &&
        errors.push(this.$t("directionoFRoadRequiredError"));
      return errors;
    },
    typeOFWorkErrors() {
      const errors = [];
      if (!this.$v.work_type.$dirty) return errors;
      !this.$v.work_type.required &&
        errors.push(this.$t("typeOFWorkRequiredError"));
      return errors;
    },
    gateCodeErrors() {
      const errors = [];
      if (!this.$v.gate_code.$dirty) return errors;
      !this.$v.gate_code.maxLength &&
        errors.push(this.$t("gateCodeLengthError"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.maxLength && errors.push(this.$t("commentsLengthError"));
      return errors;
    },
    subdivisionErrors() {
      const errors = [];
      if (!this.$v.subdivision.$dirty) return errors;
      !this.$v.subdivision.maxLength &&
        errors.push(this.$t("subdivisionLengthError"));
      return errors;
    },
    lotErrors() {
      const errors = [];
      if (!this.$v.lot.$dirty) return errors;
      !this.$v.lot.maxLength && errors.push(this.$t("lotLengthError"));
      return errors;
    },
    ticketData() {
      return this.$store.state.Ticket.ticketData;
    },
    scheduleTimeError() {
      const errors = [];
      if (!this.$v.scheduleTime.$dirty) return errors;
      !this.$v.scheduleTime.required &&
        errors.push(this.$t("scheduledTimeError"));
      return errors;
    },
    streetAddress() {
      const streetFromAddress =
        this.$store.state.Ticket.ticketData?.st_from_address;
      const street = this.$store.state.Ticket.ticketData?.street;
      return (streetFromAddress ? streetFromAddress + " " : "") + street;
    },
  },
  watch: {
    date(val) {
      this.work_date = this.formatDate(this.date);
    },
    time(val) {
      this.scheduleTime = this.time;
    },
  },
  async created() {
    this.getLegalDate();
    if (this.$store.state.Ticket.excavationDetails.workTypeOptions)
      this.workTypeOptions =
        this.$store.state.Ticket.excavationDetails.workTypeOptions;
    if (
      this.ticketData &&
      this.ticketData.center &&
      !this.$store.state.Ticket.excavationDetails.workTypeOptions
    ) {
      await this.getTypeOfWork();
    }
    if (Object.keys(this.$store.state.Ticket.excavationDetails).length != 0) {
      this.meet = this.$store.state.Ticket.excavationDetails.meet;
      if (this.meet) {
        this.date = moment(
          this.$store.state.Ticket.excavationDetails.work_date
        ).format("YYYY-MM-DD");
        this.work_date = moment(
          this.$store.state.Ticket.excavationDetails.work_date
        ).format("DD/MMM/YYYY");
      }
      this.contact = this.$store.state.Ticket.excavationDetails.contact;
      this.contact_phone =
        this.$store.state.Ticket.excavationDetails.contact_phone;
      this.work_type = this.$store.state.Ticket.excavationDetails.work_type;
      if (this.meet) this.time = await this.getTimeFromWorkDate();
      this.subdivision = this.$store.state.Ticket.excavationDetails.subdivision;
      this.lot = this.$store.state.Ticket.excavationDetails.lot;
      this.done_for = this.$store.state.Ticket.excavationDetails.done_for;
      this.extent = this.$store.state.Ticket.excavationDetails.extent;
      this.gate_code = this.$store.state.Ticket.excavationDetails.gate_code;
      this.remarks = this.$store.state.Ticket.excavationDetails.remarks;
      this.white_paint = this.$store.state.Ticket.excavationDetails.white_paint;
      this.blasting = this.$store.state.Ticket.excavationDetails.blasting;
      this.boring = this.$store.state.Ticket.excavationDetails.boring;
      this.cross1 = this.$store.state.Ticket.ticketData.streetLocation.cross1;
      this.cross2 = this.$store.state.Ticket.ticketData.streetLocation.cross2;
      this.excavation_area_in_road =
        this.$store.state.Ticket.excavationDetails.excavation_area_in_road;
      this.direction_of_road =
        this.$store.state.Ticket.excavationDetails.direction_of_road;
      this.typeOfWorkTextBox =
        this.$store.state.Ticket.excavationDetails.typeOfWorkTextBox;
      this.excavationAreaTextBox =
        this.$store.state.Ticket.excavationDetails.excavationAreaTextBox;
      if (this.$store.state.Ticket.isEdit === "UpdateTicket") {
        await this.getMembersToAdd();
        this.membersToAddList = this.$store.state.Ticket.membersToAdd;
        this.utahRemark = this.$store.state.Ticket.excavationDetails.utahRemark;
        this.utahComments =
          this.$store.state.Ticket.excavationDetails.utahComments;
        this.NewtinComment =
          this.$store.state.Ticket.excavationDetails.NewtinComment;
        this.NewtinRemark =
          this.$store.state.Ticket.excavationDetails.NewtinRemark;
        this.selectedMembersToAdd = this.$store.state.Ticket.excavationDetails
          .membersToAdd
          ? this.$store.state.Ticket.excavationDetails.membersToAdd.split(",")
          : [];
      }
      this.$nextTick(() => {
        if (this.$store.state.Ticket.excavationDetails.associateProject) {
          this.$refs.associateTicket.selectedProjectId =
            this.$store.state.Ticket.excavationDetails.associateProject;
          if (
            this.$refs.associateTicket &&
            this.$refs.associateTicket.selectedProjectId &&
            Object.keys(this.$refs.associateTicket.selectedProjectId).length !=
              0
          )
            this.$refs.associateTicket.associateTicketFlag = true;
          else this.$refs.associateTicket.associateTicketFlag = false;
        }
      });
    }
  },
  methods: {
    async getLegalDate() {
      const legalDatesData = await this.$store.dispatch("legalDatesData", {
        center: this.ticketData.center,
      });
      this.picker = legalDatesData.workDate;
      this.legalDates = legalDatesData
    },
    async getTimeFromWorkDate() {
      const workDate = await this.$store.state.Ticket.excavationDetails
        .work_date;
      const timestamp = new Date(workDate);
      const hours = timestamp.getUTCHours().toString().padStart(2, "0"); // Ensure 2-digit format
      const minutes = timestamp.getUTCMinutes().toString().padStart(2, "0"); // Ensure 2-digit format
      const formattedTime = `${hours}:${minutes}`;

      return formattedTime;
    },
    async getMembersToAdd() {
      this.membersToAddList = [];
      let membersToAddArray = [];
      if (this.ticketData) {
        const membersToAddData = await this.$store.dispatch(
          "getMembersDetailsInCounty",
          {
            state: this.ticketData.state,
            county: this.ticketData.county,
            center: this.ticketData.center,
          }
        );
        if (membersToAddData.status !== "error") {
          membersToAddArray = membersToAddData.map(({ code, name }) => {
            return {
              mbcode: code,
              name,
              mbcode_name: `${code} - ${name}`,
            };
          });
          this.membersToAddList = membersToAddArray;
          await this.$store.commit("setMembersToAdd", this.membersToAddList);
        }
      }
    },
    async continueButton() {
      if (this.meet) {
        this.work_date = new Date(this.date).toISOString();
        //   let dateTime = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD");
        //   dateTime += `T${this.time}:00.000Z`;
        //   this.scheduleTime = dateTime
        //   let excavationDetails = {};
        this.scheduleTime = new Date(this.date + " " + this.time);
      }
      let excavationDetails = {
        contact: this.contact,
        meet: this.meet,
        contact_phone: this.contact_phone,
        work_type: this.work_type,
        subdivision: this.subdivision,
        lot: this.lot,
        done_for: this.done_for,
        extent: this.extent,
        gate_code: this.gate_code,
        remarks: this.remarks,
        white_paint: this.white_paint,
        blasting: this.blasting,
        boring: this.boring,
        excavation_area_in_road: this.excavation_area_in_road,
        direction_of_road: this.direction_of_road,
        typeOfWorkTextBox: this.typeOfWorkTextBox,
        excavationAreaTextBox: this.excavationAreaTextBox,
        workTypeOptions: this.workTypeOptions,
      };
      if (this.meet) {
        excavationDetails.work_date = this.scheduleTime;
      }
      if (
        this.$refs &&
        this.$refs.associateTicket &&
        this.$refs.associateTicket.selectedProjectId
      ) {
        excavationDetails.associateProject =
          this.$refs.associateTicket.selectedProjectId;
      }
      this.$store.state.Ticket.ticketData.streetLocation.cross1 = this.cross1;
      this.$store.state.Ticket.ticketData.streetLocation.cross2 = this.cross2;

      if (
        !this.$store.state.Ticket.ticketData.street &&
        this.$store.state.Ticket.ticketData.street != ""
      )
        this.$store.state.Ticket.ticketData.street = this.street;

      if (
        !this.$store.state.Ticket.ticketData.county &&
        this.$store.state.Ticket.ticketData.county != ""
      )
        this.$store.state.Ticket.ticketData.county = this.county;

      if (
        !this.$store.state.Ticket.ticketData.place &&
        this.$store.state.Ticket.ticketData.place != ""
      )
        this.$store.state.Ticket.ticketData.place = this.place;
      if (this.$store.state.Ticket.isEdit === "UpdateTicket") {
        excavationDetails.utahRemark = this.utahRemark;
        excavationDetails.utahComments = this.utahComments;
        excavationDetails.NewtinComment = this.NewtinComment;
        excavationDetails.NewtinRemark = this.NewtinRemark;
        excavationDetails.membersToAdd = this.selectedMembersToAdd.join(",");
      }
      this.$store.commit("setExcavationDetails", excavationDetails);
      this.$emit("excavationDetailsCompleted");
    },
    async getTypeOfWork() {
      const centerObject = {};
      centerObject["center"] = this.ticketData.center;
      const workTypeStatus = await this.$store.dispatch(
        "getTypeOfWork",
        centerObject
      );
      let workTypeArray = [];
      if (workTypeStatus.status !== "error") {
        workTypeStatus.map((ele) => {
          let tempStr = ele.description.replace(
            /(\w)(\w*)/g,
            function (g0, g1, g2) {
              return g1.toUpperCase() + g2.toLowerCase();
            }
          );
          workTypeArray.push(tempStr);
        });
        workTypeArray.push("Other");
        this.workTypeOptions = workTypeArray;
      }
    },
    backButton() {
      this.$emit("excavationDetailsBack");
    },
    setStartDate(tempDate) {
      this.startDate = tempDate;
    },
    enableExcavationAreaTextBox() {
      this.excavationAreaTextBox = true;
      this.extent = "";
    },
    disableExcavationAreaTextBox() {
      this.excavationAreaTextBox = false;
      this.extent = "";
    },
    emptyExcavationArea() {
      if (this.extent === "Other") {
        this.excavationAreaTextBox = true;
        this.extent = "";
      }
    },
    disableWeekends(date) {
      const day = new Date(date).getDay();
      return !(day === 0 || day === 6);
    },
    enableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = true;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    disableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = false;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    emptyWorkType() {
      if (this.work_type === "Other") {
        this.typeOfWorkTextBox = true;
        this.work_type = "";
        this.$nextTick(() => {
          this.$v.work_type.$reset();
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>
